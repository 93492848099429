import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelBdevent.mjs'

const PropertiesPaneBdevent = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="eventTitle" />
                <Text prop="eventUserCode" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Depend',
                    'Effect',
                    'Reference'
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneBdevent
