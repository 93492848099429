import {Button, Modal} from 'antd'
import http from '@/biz/http.mjs'
import openWindow from '@/script/openWindow.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonMakeSf(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    let commonBdId = ''
    const ruleList = []

    for (const n of selectedNodes) {
        const {bdId, bizNodeType, pkid, ruleCode, ruleType} = n.data

        if (! (
            pkid &&
            'BDLOGIC' === bizNodeType &&
            'BT' === ruleType
        )) {
            return null
        }

        if (commonBdId) {
            if (bdId !== commonBdId) {
                return null
            }
        }
        else {
            commonBdId = bdId
        }

        ruleList.push({ruleCode})
    }

    if (! commonBdId) {
        return null
    }

    const handleClick = async () => {
        const {prjId} = map.data

        await runAsync(
            () => http.post(
                '/dmbds/rule/genSf',
                {pkid: commonBdId, prjId, ruleList}
            ),

            {action: '生成交互功能'}
        )

        const onOk = e => {
            const target = e.altKey ? '_self' : '_blank'
            openWindow('/SfList', {} ,target)
        }

        Modal.confirm({
            cancelText: '我已了解',
            content: '生成交互功能成功',
            okText: '去查看',
            onOk,
            title: '提示',
        })
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >生成交互功能</Button>
    )
}
