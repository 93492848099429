import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaDtf.mjs'
import PropertiesPane from './PropertiesPaneDtf.jsx'
import Model from './ModelDtf.mjs'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        attrNodes: {
            bottom: ['ATTR_DF_DESC'],
        },

        canLinkType(map, node, type) {
            return /^(DTO)$/.test(type)
        },

        canWriteTree(map, node, type) {
            return false
        },

        getIcons(map, node) {
            return map.BizNode.DF.getIcons.call(this, map, node)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.CYAN})
        },

        getTextSuffix(map, node) {
            const {
                dataTypeCode,
                dataTypeGrpCode,
                dfDtoUserCode,
                dfUserCode,
                pkid,
            } = node.data

            if (! pkid) {
                return ''
            }

            if (dataTypeGrpCode) {
                if (
                    /^(Basic|Other)$/.test(dataTypeGrpCode) &&
                    dataTypeCode
                ) {
                    return ` ${dataTypeCode} ${dfUserCode}`
                }
                else if (
                    'DTO' === dataTypeGrpCode &&
                    dfDtoUserCode
                ) {
                    return ` ${dfDtoUserCode} ${dfUserCode}`
                }
                else if (dataTypeCode || dfDtoUserCode) {
                    const type = dataTypeCode ?? dfDtoUserCode
                    return ` ${dataTypeGrpCode}<${type}> ${dfUserCode}`
                }
            }

            return ` ${dfUserCode}`
        },

        onPush(map, node, type, data) {
            if ('DTO' !== type) {
                return
            }

            const pushData = this._getPushData(map, node)
            data.dtoDfList.push(pushData)
        },

        async _onChange(map, node, event) {
            const oldData = event.detail
            const newData = node.data

            if (
                oldData.dataTypeGrpCode !== newData.dataTypeGrpCode &&
                ! /^(DTO|List)$/.test(newData.dataTypeGrpCode)
            ) {
                map.deleteChildren(node)
            }

            if (
                newData.dfDtoId &&
                oldData.dfDtoId !== newData.dfDtoId
            ) {
                map.deleteChildren(node)
                const data = await map.BizNode.DTO.read({pkid: newData.dfDtoId})
                const dto = map.importTree({data})
                map.appendChild(node, dto)
            }
        },
    })
}
