import TextArea from '@/components/Form/TextArea.jsx'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectBasicDataType from '../components/SelectBasicDataType.jsx'
import SelectDto from '../DTO/SelectDto.jsx'

export default function PropertiesControllerDataType({
    className,
    style,
    ...props
}) {
    const [values, update] = useProperties()
    const p = usePropertiesController({prop: 'dataTypeCode'})

    const {
        dataTypeCode,
        dataTypeGrpCode: grpCode,
        dfDtoCode: dtoCode,
        dfDtoName: dtoName,
        dfDtoUserCode: dtoUserCode,
        ssCode,
    } = values

    const input = (() => {
        if ('Basic' === grpCode) {
            const handleChange = (dataTypeCode) => {
                update({dataTypeCode})
            }

            return (
                <SelectBasicDataType
                    disabled={p.disabled}
                    readOnly={p.readOnly}
                    value={dataTypeCode}
                    onChange={handleChange}
                    {...props}
                />
            )
        }
        else if ('Other' === grpCode) {
            const handleChange = (dataTypeCode) => {
                update({dataTypeCode})
            }

            return (
                <TextArea
                    autoSize={{minRows: 3}}
                    disabled={p.disabled}
                    readOnly={p.readOnly}
                    value={dataTypeCode}
                    onChange={handleChange}
                    {...props}
                />
            )
        }
        else {
            const handleChange = ({
                dataTypeCode,
                dtoCode,
                dtoId,
                dtoName,
                dtoUserCode,
                rev,
            }) => {
                update({
                    dfDtoCode: dtoCode,
                    dfDtoId: dtoId,
                    dfDtoName: dtoName,
                    dfDtoRev: rev,
                    dfDtoUserCode: dtoUserCode,
                    dataTypeCode,
                })
            }

            return (
                <SelectDto
                    dataType={'DTO' === grpCode ? 'Ref' : undefined}
                    disabled={p.disabled}
                    readOnly={p.readOnly}
                    ssCode={ssCode}

                    value={{
                        dataTypeCode,
                        dtoCode,
                        dtoName,
                        dtoUserCode,
                        grpCode,
                    }}

                    onChange={handleChange}
                />
            )
        }
    })()

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            required={p.required}
        >
            {input}
        </PropertiesController>
    )
}
