import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useCommonNode from '../_COMMON/useCommonNode.jsx'
import IconLink from './IconLink.jsx'
import PropertiesPane from './PropertiesPaneUrl.jsx'
import meta from './metaUrl.mjs'

export default () => {
    const CommonNode = useCommonNode()

    return extendNode(CommonNode, {
        ...meta,
        PropertiesPane,

        getIcons(map, node) {
            return [<IconLink key="type"/>]
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
                Shape: 'SingleBreakangle',
            }
        },

        async onDoubleClick(map, node, event) {
            const {address} = node.data

            if (address) {
                event.preventDefault()
                const target = event.altKey ? '_self' : '_blank'
                window.open(address, target)
            }
        },
    })
}
