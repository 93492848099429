import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ProgType from './ProgType.mjs'
import Model from './ModelIprog.mjs'

const PropertiesPaneIprog = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <LongText prop="progTitle" />

                <Select
                    options={ProgType}
                    prop="progTypeCode"
                />

                <LongText prop="progDesc" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference'
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneIprog
