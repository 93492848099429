import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/RightPanel/PropertiesControllerCheck.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Password from '../../SidePanel/RightPanel/PropertiesControllerPassword.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelDbins.mjs'
import Url from './PropertiesControllerUrl.jsx'

const PropertiesPaneDbins = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="dbInsName" />
                <LongText prop="dbInsDesc" />
                <LongText prop="dbInsMemo" />
                <Text prop="dbInsVersion" />

                <Check
                    falseValue={0}
                    trueValue={1}
                    prop="dbDefault" />

                <Text prop="dbInsIntranetIp" />
                <Text prop="dbInsInternetIp" />
                <Text prop="dbInsPort" />
                <Text prop="dbInsSchema" />
                <Text prop="dbInsUserName" />

                <Password
                    autocomplete="new-password"
                    prop="dbInsUserPassword"
                />

                <Url />

                <Select
                    options={[
                        ['NORMAL', '正常'],
                        ['DAMAGED', '损坏'],
                        ['REPAIR', '修理'],
                        ['MAINTAIN', '保养'],
                        ['UNKNOWN', '未知'],
                    ]}

                    prop="dbInsStatus"
                />

                <Text prop="dbInsBu" />
                <Text prop="dbInsDm" />
                <Text prop="dbInsOm" />
                <Text prop="dbInsEnv" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference'
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneDbins
