import {Button, Modal} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function ButtonLastRev({node, ...props}) {
    const map = useMapContext()
    const {lastRev, sVer} = node.data
    const _n = map.nodeProxy(node)
    const rev = _n.getRev()

    if (! (
        lastRev &&
        sVer &&
        (rev || 0 === rev) &&
        ! _n.canWriteData()
    )) {
        return null
    }

    const handleClick = async e => {
        if (rev < lastRev) {
            const target = e.altKey ? '_self' : '_blank'
            await _n.gotoDiff(rev, lastRev, target)
        }
        else {
            Modal.info({content: '已是最新版本'})
        }
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            最新: {sVer}.{lastRev}
        </Button>
    )
}
