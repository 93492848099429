export const canDeleteNode = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const _n = this.nodeProxy(node)

        if (! _n.canDeleteNode()) {
            return false
        }

        return defaultActions.canDeleteNode(node)
    }
}

export const deleteNode = () => defaultActions => {
    return async function (nodes) {
        await defaultActions.deleteNode(nodes)

        await Promise.all(
            nodes.map(async n => {
                const _n = this.nodeProxy(n)
                await _n.emitEventUp({type: 'delete'})
            })
        )
    }
}
