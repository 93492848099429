import useSWR from 'swr/immutable'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import ButtonImportProducts from '../components/PropertiesPaneButtonImportProducts.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import CategoryRootPropertiesPane from '../_CATEGORY_ROOT/CategoryRootPropertiesPane.jsx'

const useFm = (fmId) => {
    const fetchFm = (fmId) => runAsync(
        () => http.get(`/archfms/${fmId}`),

        {
            error: {
                content: (err) => `读取功能模块失败: ${err.message}`,
            },

            loading: {
                content: '正在读取功能模块，请稍候',
            },
        }
    )

    const {data} = useSWR(fmId, fetchFm)
    return data
}

export default function PropertiesPaneCatRootFm({nodes, ...props}) {
    const fm = useFm(nodes[0].data.fmId)

    if (! fm) {
        return null
    }

    return (
        <CategoryRootPropertiesPane
            buttons={<ButtonImportProducts />}

            controllers={
                <>
                    <Text
                        label="功能模块代码"
                        prop="fmUserCode"
                    />

                    <Text
                        label="功能模块名称"
                        prop="fmName"
                    />
                </>
            }

            properties={fm}
            {...props}
        />
    )
}
