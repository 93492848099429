import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaLsiSf.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(BDLOGIC|SF)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['BDLOGIC_1'],
            ]
        },

        onPush(map, node, type, data) {
            if ('LSI' !== type) {
                return
            }

            Object.assign(
                data,

                {
                    openPrjId: '',
                    openSVer: '',
                    patternRev: '',
                    prjPatternId: '',
                    sfCode: '',
                    sfRev: '',
                }
            )

            for (const n of node.children) {
                const {
                    bizNodeType,
                    patternRev,
                    prjId: openPrjId,
                    prjPatternId,
                    rev: sfRev,
                    sVer: openSVer,
                } = n.data

                const sfCode = (() => {
                    if ('BDLOGIC' === bizNodeType) {
                        return n.data.ruleCode
                    }
                    else if ('SF' === bizNodeType) {
                        return n.data.sfCode
                    }
                })()

                if (sfCode) {
                    Object.assign(data, {
                        openPrjId,
                        openSVer,
                        patternRev,
                        prjPatternId,
                        sfCode,
                        sfRev,
                    })

                    break
                }
            }
        },
    })
}
