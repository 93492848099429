import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDt.mjs'
import Model from './ModelDt.mjs'
import PropertiesPane from './PropertiesPaneDt.jsx'
import FormChoose from './FormChooseDt.jsx'
import TableChoose from './TableChooseDt.jsx'
import api from './apiDt.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(CAT|BK|DF|PK)$/.test(type)
        },

        async choose(map, node) {
            const {prjNo} = map.data

            const getQuery = ({dms = [], ...query}) => {
                const dbCode = (() => {
                    const p = node.trueParent

                    if (! p) {
                        return
                    }

                    const {bizNodeType} = p.data

                    if ('DV_DS' === bizNodeType) {
                        return p.parent.data.dbCode
                    }
                })()

                return {
                    prjNo,
                    ...query,
                    dbCode,
                    dmIds: dms.map(({dmId}) => dmId),
                }
            }

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            return 'DF'
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.DARK_CYAN}
                        letters="DT"
                        textColor="#666"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {dmDfList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.CYAN,
                }),

                shape: 'SingleBreakangle',
            }
        },

        getTextSuffix(map, node) {
            const {dtAlias} = node.data

            if (dtAlias) {
                return ` (${dtAlias})`
            }
            else {
                return ProductNode.getTextSuffix.call(this, map, node)
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['dmDfList'])
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['DF'],
            ]
        },

        async _onAttach(map, node, event) {
            const n = event.target
            const {bizNodeType, isBk, isPk} = n.data

            if ('DF' === bizNodeType) {
                if ('1' === isBk) {
                    // 不使用正则替换的原因是无法处理以下情况：
                    // 需处理的字段A名称包含分隔符，而字段B名称为空

                    const dfName = this._getKeyName(map, node, 'isBk')
                    const bk = this._findBk(map, node)

                    if (bk) {
                        bk.data = {...bk.data, dfName}
                    }
                    else {
                        const bk = map.createNode('BK')
                        bk.data = {...bk.data, dfName}

                        const pk = [...node.children].find(
                            e => 'PK' === e.data.bizNodeType
                        )

                        if (pk) {
                            map.insertSiblingAfter(pk, bk)
                        }
                        else {
                            map.prependChild(node, bk)
                        }
                    }
                }

                if ('1' === isPk) {
                    // 不使用正则替换的原因是无法处理以下情况：
                    // 需处理的字段A名称包含分隔符，而字段B名称为空

                    const dfName = this._getKeyName(map, node, 'isPk')
                    const pk = this._findPk(map, node)

                    if (pk) {
                        pk.data = {...pk.data, dfName}
                    }
                    else {
                        const pk = map.createNode('PK')
                        pk.data = {...pk.data, dfName}
                        map.prependChild(node, pk)
                    }
                }
            }

            await ProductNode._onAttach.call(this, map, node, event)
        },

        async _onChange(map, node, event) {
            const n = event.target
            const oldData = event.detail
            const newData = n.data

            if (n === node) {
                const {dbCode} = newData

                if (oldData.dbCode && dbCode !== oldData.dbCode) {
                    node.data = {
                        ...node.data,
                        dmCode: '',
                        dmId: '',
                        dmName: '',
                    }
                }
            }
            else if (
                'DF' === newData.bizNodeType &&

                (
                    ! newData.pkid ||
                    node.data.pkid === newData.dtId
                )
            ) {
                if (
                    newData.isBk !== oldData.isBk ||

                    (
                        '1' === newData.isBk &&
                        newData.dfName !== oldData.dfName
                    )
                ) {
                    // 不使用正则替换的原因是无法处理以下情况：
                    // 需处理的字段A名称包含分隔符，而字段B名称为空

                    const dfName = this._getKeyName(map, node, 'isBk')
                    const bk = this._findBk(map, node)

                    if (bk) {
                        if (dfName) {
                            bk.data = {...bk.data, dfName}
                        }
                        else {
                            map.deleteTree(bk)
                        }
                    }
                    else if (dfName) {
                        const bk = map.createNode('BK')
                        bk.data = {...bk.data, dfName}

                        const pk = [...node.children].find(
                            e => 'PK' === e.data.bizNodeType
                        )

                        if (pk) {
                            map.insertSiblingAfter(pk, bk)
                        }
                        else {
                            map.prependChild(node, bk)
                        }
                    }
                }

                if (
                    newData.isPk !== oldData.isPk ||

                    (
                        '1' === newData.isPk &&
                        newData.dfName !== oldData.dfName
                    )
                ) {
                    // 不使用正则替换的原因是无法处理以下情况：
                    // 需处理的字段A名称包含分隔符，而字段B名称为空

                    const dfName = this._getKeyName(map, node, 'isPk')
                    const pk = this._findPk(map, node)

                    if (pk) {
                        if (dfName) {
                            pk.data = {...pk.data, dfName}
                        }
                        else {
                            map.deleteTree(pk)
                        }
                    }
                    else if (dfName) {
                        const pk = map.createNode('PK')
                        pk.data = {...pk.data, dfName}
                        map.prependChild(node, pk)
                    }
                }
            }

            await ProductNode._onChange.call(this, map, node, event)
        },

        async _onDelete(map, node, event) {
            const n = event.target
            const {bizNodeType, isBk, isPk} = n.data

            if ('DF' === bizNodeType) {
                if ('1' === isBk) {
                    const bk = this._findBk(map, node)

                    if (! bk) {
                        return
                    }

                    // 不使用正则替换的原因是无法处理以下情况：
                    // 需处理的字段A名称包含分隔符，而字段B名称为空

                    const dfName = this._getKeyName(map, node, 'isBk')

                    if (dfName) {
                        bk.data = {...bk.data, dfName}
                    }
                    else {
                        map.deleteTree(bk)
                    }
                }

                if ('1' === isPk) {
                    const pk = this._findPk(map, node)

                    if (! pk) {
                        return
                    }

                    // 不使用正则替换的原因是无法处理以下情况：
                    // 需处理的字段A名称包含分隔符，而字段B名称为空

                    const dfName = this._getKeyName(map, node, 'isPk')

                    if (dfName) {
                        pk.data = {...pk.data, dfName}
                    }
                    else {
                        map.deleteTree(pk)
                    }
                }
            }

            await ProductNode._onChange.call(this, map, node, event)
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    dbCode,
                    dbId,
                    dbName,
                    dmCode,
                    dmId,
                    dmName,
                } = n.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        dbCode,
                        dbId,
                        dbName,
                        dmCode,
                        dmId,
                        dmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        _findBk(map, node) {
            return [...node.children].find(
                e => 'BK' === e.data.bizNodeType
            )
        },

        _findPk(map, node) {
            return [...node.children].find(
                e => 'PK' === e.data.bizNodeType
            )
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('DGF' === bizNodeType) {
                map.BizNode.DF.castFrom(map, child)
                return true
            }
            else {
                return ProductNode._fixChild.call(this, map, node, child)
            }
        },

        _getKeyName(map, node, isKeyProp) {
            const next = chain => {
                const {bizNodeType, [isKeyProp]: isKey} = chain[0].data
                const _n = map.nodeProxy(chain[0])
                const isMounted = _n.isMounted()

                const yieldNode = (
                    isMounted &&
                    'DF' === bizNodeType &&
                    '1' === isKey
                )

                const yieldChildren = isMounted && 'DF' !== bizNodeType
                return {yieldChildren, yieldNode}
            }

            return [...map.dfs(node.children, next)]
                .map(e => e.data.dfName)
                .join('+')
        },

        async _onInsert(map, node) {
            const p = node.trueParent

            if (p) {
                const {arTypeCode, bizNodeType} = p.data

                if (
                    'AR' === bizNodeType &&
                    /^DB_[CU]$/.test(arTypeCode)
                ) {
                    const {pkid} = node.data
                    const {children} = await map.BizNode.DT.readTree({pkid})
                    const ar = map.createNode('AR')
                    ar.data = {...ar.data, arName: '', arTypeCode: 'SET_DF'}
                    map.insertSiblingAfter(node, ar)
                    await map.BizNode.AR.emitEventUp(map, ar, {type: 'create'})

                    for (const tree of children) {
                        const {bizNodeType} = tree.data

                        if (/^[BP]K$/.test(bizNodeType)) {
                            continue
                        }

                        const child = map.importTree(tree)
                        map.appendChild(ar, child)
                        // TODO
                        //await map.BizNode[bizNodeType].onAttachTo(map, child)
                    }
                }
            }

            await ProductNode._onInsert.call(this, map, node)
        },
    })
}
