import BaseMap from '../../BaseMap.jsx'
import hotkeys from './hotkeys.mjs'
import useMapData from './useMapData.mjs'
import useMindMapPlugins from './useMindMapPlugins.mjs'
import ToolBar from './ToolBar/ToolBar.jsx'

const components = {ToolBar}

const HierarchyMap = ({id, patternRev, prjPatternId, type, ...props}) => {
    const initData = useMapData(id, type)

    if (initData) {
        Object.assign(initData.data, {patternRev, prjPatternId})
    }

    return (
        <BaseMap
            components={components}
            hotkeys={hotkeys}
            initData={initData}
            plugins={[useMindMapPlugins()]}
            {...props}
        />
    )
}

export default HierarchyMap
