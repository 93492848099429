import {publish} from '@/script/event.mjs'

const running = new WeakSet

export const execute = async (target, fn) => {
    if (running.has(target)) {
        try {
            await fn()
            publish(target, 'task_execute')
        }
        catch (err) {
            publish(target, 'task_execute_fail')
            throw err
        }
    }
    else {
        start(target)

        try {
            await fn()
            publish(target, 'task_execute')
            finish(target)
        }
        catch (err) {
            publish(target, 'task_execute_fail')
            fail(target)
            throw err
        }
    }
}

export const fail = target => {
    if (running.has(target)) {
        running.delete(target)
        publish(target, 'task_fail')
    }
    else {
        throw new Error('Task is not running.')
    }
}

export const finish = target => {
    if (running.has(target)) {
        running.delete(target)
        publish(target, 'task_finish')
    }
    else {
        throw new Error('Task is not running.')
    }
}

export const start = target => {
    if (running.has(target)) {
        throw new Error('Task is already running.')
    }
    else {
        running.add(target)
        publish(target, 'task_start')
    }
}
