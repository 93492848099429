import Table from '@/components/Table/Table.jsx'
import TableChooseDesignItems from '../components/TableChooseDesignItems.jsx'

const TableChooseBdrule = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '业务对象/服务名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '处理任务标题',
            dataIndex: 'ruleTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bdlogic_2s"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableChooseBdrule
