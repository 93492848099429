/* eslint-disable react/jsx-key */

import IconLetters from '../icons/IconLetters.jsx'
import IconInf from './icon-inf.svg?react'
import IconTime from './icon-time.svg?react'

export default [
    [
        'UI',
        '业务交互功能',
    ],

    [
        'EXPERIENCE',
        '体验增强功能',

        <IconLetters
            fill="#fff"
            letters="X"
            textColor="#000"
        />
    ],

    [
        'INF',
        '接口功能',
        <IconInf />
    ],

    [
        'TIME',
        '定时功能',
        <IconTime />
    ],
]
