import useSWR from 'swr/immutable'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import CategoryRootPropertiesPane from '../_CATEGORY_ROOT/CategoryRootPropertiesPane.jsx'

const useUm = (umId) => {
    const fetchUm = (umId) => runAsync(
        () => http.get(`/archums/${umId}`),

        {
            error: {
                content: (err) => `读取界面入口失败: ${err.message}`,
            },

            loading: {
                content: '正在读取界面入口，请稍候',
            },
        }
    )

    const {data} = useSWR(umId, fetchUm)
    return data
}

export default function PropertiesPaneCatRootUm({nodes, ...props}) {
    const um = useUm(nodes[0].data.umId)

    if (! um) {
        return null
    }

    return (
        <CategoryRootPropertiesPane
            controllers={
                <>
                    <Text
                        label="界面入口代码"
                        prop="umUserCode"
                    />

                    <Text
                        label="界面入口名称"
                        prop="umName"
                    />
                </>
            }

            properties={um}
            {...props}
        />
    )
}
