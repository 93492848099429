import {TreeNode} from '@/script/Tree.mjs'

class _TreeDocNode extends TreeNode {
    constructor(id) {
        super(id)
    }

    get descendantCount() {
        return this.#descendantCount
    }

    set descendantCount(count) {
        this.#descendantCount = count
    }

    get isFolded() {
        return this.#isFolded
    }

    set isFolded(isFolded) {
        this.#isFolded = isFolded
    }

    get isHidden() {
        return this.#isHidden
    }

    set isHidden(isHidden) {
        this.#isHidden = isHidden
    }

    export(transform = a => a) {
        const {isFolded, isHidden} = this

        return transform({
            ...super.export(),
            isFolded,
            isHidden,
        })
    }

    #descendantCount = 0
    #isFolded = false
    #isHidden = false
}

export default class TreeDocNode extends _TreeDocNode {
    constructor(doc, id) {
        super(id)
        this.#doc = doc
    }

    get chain() {
        return this.doc.chain(this)
    }

    get children() {
        return this.doc.children(this)
    }

    get data() {
        return super.data
    }

    set data(data) {
        this.#update('data', data)
    }

    get descendantCount() {
        return super.descendantCount
    }

    set descendantCount(count) {
        const oldCount = this.#update('descendantCount', count)

        if (
            this.parent &&
            oldCount !== count
        ) {
            this.parent.descendantCount += count - oldCount
        }
    }

    get doc() {
        return this.#doc
    }

    get firstChild() {
        return super.firstChild
    }

    set firstChild(node) {
        this.#update('firstChild', node)
    }

    get isDeleted() {
        return void 0 === this.#doc.getNode(this.id)
    }

    get isFolded() {
        return (
            null !== this.firstChild &&
            super.isFolded
        )
    }

    set isFolded(isFolded) {
        this.#update('isFolded', isFolded)
    }

    get isHidden() {
        return super.isHidden
    }

    set isHidden(isHidden) {
        this.#update('isHidden', isHidden)
    }

    get lastChild() {
        return super.lastChild
    }

    set lastChild(node) {
        this.#update('lastChild', node)
    }

    get nextSibling() {
        return super.nextSibling
    }

    set nextSibling(node) {
        this.#update('nextSibling', node)
    }

    get parent() {
        return super.parent
    }

    set parent(node) {
        const oldParent = this.#update('parent', node)

        if (oldParent === this.parent) {
            return
        }

        if (oldParent) {
            oldParent.descendantCount -= this.descendantCount + 1
        }

        if (this.parent) {
            this.parent.descendantCount += this.descendantCount + 1
        }
    }

    get prevSibling() {
        return super.prevSibling
    }

    set prevSibling(node) {
        this.#update('prevSibling', node)
    }

    change(type, newValue, oldValue) {
        this.#doc._changeNode(this, type, newValue, oldValue)
    }

    #doc

    #update(prop, newValue) {
        const oldValue = this[prop]

        if (oldValue !== newValue) {
            super[prop] = newValue
            this.#doc._updateNode(this, prop, newValue, oldValue)
        }

        return oldValue
    }
}
