import {css} from '@emotion/react'
import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import SidePane from '../../SidePanel/SidePane.jsx'
import {PropertiesContext} from '../../SidePanel/RightPanel/Properties.mjs'
import ButtonEditMap from './PropertiesPaneButtonEditMap.jsx'

const cssButtons = css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
})

export default function CategoryRootPropertiesPane({
    buttons,
    controllers,
    properties,
    ...props
}) {
    return (
        <PropertiesContext.Provider value={[properties]}>
            <ReadOnlyContext.Provider value={true}>
                <SidePane {...props}>
                    {controllers}

                    <div css={cssButtons}>
                        <ButtonEditMap />
                        {buttons}
                    </div>
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}
