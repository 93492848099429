export const canClearNodeText = () => () => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const _n = this.nodeProxy(node)
        return _n.canWriteData()
    }
}

export const clearNodeText = () => () => {
    return async function (nodes) {
        if (0 === nodes.length) {
            return
        }

        for (const n of nodes) {
            this.behaviours.editNodeText(n, '')
        }

        this.logger.info('已清除节点文本', nodes)
    }
}
