import {Button} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

export default function PropertiesControllerProjectDetail(props) {
    const [values] = useProperties()
    const {prjId, prjName, prjNo} = values

    const handleClickDetail = e => {
        openWindow(
            '/ProjectDetail',
            {prjId, prjName, prjNo},
            e.altKey ? '_self' : '_blank'
        )
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClickDetail}
            {...props}
        >项目详情</Button>
    )
}
