import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from '../_ALG/ModelAlg.mjs'
import ButtonMakeApi from './PropertiesPaneButtonMakeApi.jsx'

const PropertiesPaneAlg = (props) => {
    const [{algType}] = useProperties()

    return (
        <ComponentPropertiesPane
            buttons={
                <>
                    <ButtonMakeApi />
                </>
            }

            controllers={
                <>
                    <Text prop="algTitle" />
                    <Text prop="algUserCode" />

                    {
                        'CONCEPT' !== algType &&
                            <TagSelect prop="algKey" />
                    }
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                        'ChgReference',
                        'Depend',
                        'Effect',
                        'Reference'
                    ]}
                />
            }

            {...props}
        />
    )
}

export default PropertiesPaneAlg
