import {publish, subscribe} from '@/script/event.mjs'
import * as task from '@/script/task.mjs'
import Tree from '@/script/Tree.mjs'
import TreeDocNode from './TreeDocNode.mjs'

const isNodeRef = prop => {
    return /^(parent|(first|last)Child|(prev|next)Sibling)$/.test(prop)
}

const Instruction = {
    CREATE: 0,
    DELETE: 1,
    UPDATE: 2,
}

export default class TreeDoc extends Tree {
    static TreeNode = TreeDocNode

    constructor() {
        super()

        subscribe(this, 'task_execute', () => {
            if (0 === this.#changes.size) {
                return
            }

            const changeChildren = (node) => {
                if (! node) {
                    return
                }

                let changes = this.#changes.get(node)

                if (! changes) {
                    changes = new Map
                    this.#changes.set(node, changes)
                }

                const type = 'children'
                changes.set(type, {node, type})
            }

            for (const [node, changes] of this.#changes) {
                const isDeletedChange = changes.get('isDeleted')
                const parentChange = changes.get('parent')

                if (parentChange) {
                    changeChildren(parentChange.oldValue)
                    changeChildren(parentChange.newValue)
                }
                else if (isDeletedChange) {
                    changeChildren(node.parent)
                }
                else if (
                    changes.has('prevSibling') ||
                    changes.has('nextSibling')
                ) {
                    changeChildren(node.parent)
                }
            }

            // 上一步会产生新的变更，需要再次迭代
            for (const [node, changes] of this.#changes) {
                if (! node.isDeleted) {
                    publish(node, 'change', changes)
                }
            }

            publish(this, 'model_change', this.#changes)
            this.#instructions.push([])
            this.#resetChanges()
        })

        subscribe(this, 'task_execute_fail', () => {
            const instructions = this.#instructions.pop()

            if (0 < instructions.length) {
                this.revert(instructions)
            }

            this.#instructions.push([])
            this.#resetChanges()
        })

        subscribe(this, 'task_fail', () => {
            const instructions = this.#instructions.flat()

            if (0 < instructions.length) {
                this.revert(instructions)
                this.#resetInstructions()
                this.#resetChanges()
            }
        })

        subscribe(this, 'task_finish', () => {
            const instructions = this.#instructions.flat()

            if (0 < instructions.length) {
                publish(this, 'commit', instructions)
                this.#resetInstructions()
            }
        })
    }

    deleteTree(node) {
        super.deleteTree(node)

        if (node.parent) {
            node.parent.descendantCount -= node.descendantCount + 1
        }
    }

    init(tree) {
        super.init(tree)

        // 初始化产生的变更和指令不应保留
        this.#resetChanges()
        this.#resetInstructions()
    }

    do(instructions) {
        // 存放不需要的临时指令
        this.#instructions.push([])

        const ops = [
            // CREATE
            id => {
                const node = this._createNode(id)
                this._addNode(node)
            },

            // DELETE
            ({id}) => {
                const node = this.getNode(id)
                this._deleteNode(node)
            },

            // UPDATE
            ([id, prop, newValue]) => {
                const node = this.getNode(id)
                const value = this.#deserializeNodeProp(prop, newValue)
                node[prop] = value
            },
        ]

        for (const [type, data] of instructions) {
            ops[type](data)
        }

        // 放弃产生的临时指令
        this.#instructions.pop()
    }

    revert(instructions) {
        // 存放不需要的临时指令
        this.#instructions.push([])

        const deleted = []

        const ops = [
            // CREATE
            id => {
                const node = this.getNode(id)
                this._deleteNode(node)
            },

            // DELETE
            ({id, ...nodeData}) => {
                const node = this._createNode(id)
                this._addNode(node)
                deleted.push([node, nodeData])
            },

            // UPDATE
            ([id, prop, , oldValue]) => {
                const node = this.getNode(id)
                const value = this.#deserializeNodeProp(prop, oldValue)
                node[prop] = value
            },
        ]

        const reverse = function* (arr) {
            for (let i = arr.length - 1; -1 < i; i -= 1) {
                yield arr[i]
            }
        }

        for (const [type, data] of reverse(instructions)) {
            ops[type](data)
        }

        // 被删除的节点对其他节点的引用，可能指向同样被删除的节点，
        // 故需待所有被删除节点都被重新创建之后，再重新建立关系
        for (const [node, nodeData] of deleted) {
            for (const [p, v] of Object.entries(nodeData)) {
                const value = this.#deserializeNodeProp(p, v)
                node[p] = value
            }
        }

        // 放弃产生的临时指令
        this.#instructions.pop()
    }

    async execute(fn) {
        await task.execute(this, fn)
    }

    failTask() {
        task.fail(this)
    }

    finishTask() {
        task.finish(this)
    }

    startTask() {
        task.start(this)
    }

    _addNode(node) {
        super._addNode(node)
        this._changeNode(node, 'isCreated', true, false)
        this.#log(Instruction.CREATE, node.id)
    }

    _changeNode(node, type, newValue, oldValue) {
        if (newValue === oldValue) {
            return
        }

        if (! this.#changes.has(node)) {
            this.#changes.set(node, new Map)
        }

        const changes = this.#changes.get(node)
        const change = changes.get(type)

        if (change) {
            changes.set(type, {newValue, node, oldValue: change.oldValue})
        }
        else {
            changes.set(type, {newValue, node, oldValue})
        }
    }

    _createNode(id) {
        const {TreeNode} = this.constructor
        return new TreeNode(this, id)
    }

    _deleteNode(node) {
        super._deleteNode(node)
        this._changeNode(node, 'isDeleted', true, false)

        this.#log(
            Instruction.DELETE,

            {
                ...node.export(),
                parent: node.parent && node.parent.id,
                firstChild: node.firstChild && node.firstChild.id,
                lastChild: node.lastChild && node.lastChild.id,
                prevSibling: node.prevSibling && node.prevSibling.id,
                nextSibling: node.nextSibling && node.nextSibling.id,
            }
        )
    }

    _updateNode(node, prop, newValue, oldValue) {
        this._changeNode(node, prop, newValue, oldValue)

        this.#log(
            Instruction.UPDATE,

            [
                node.id,
                prop,
                this.#serializeNodeProp(prop, newValue),
                this.#serializeNodeProp(prop, oldValue),
            ]
        )
    }

    /**
     * 记录单次任务执行时节点的变更
     */
    #changes = new Map

    /**
     * 记录任务开始/结束期间每次执行产生的指令
     */
    #instructions = [[]]

    #deserializeNodeProp(prop, value) {
        if (isNodeRef(prop)) {
            if (value instanceof this.constructor.TreeNode) {
                return value
            }
            else {
                const node = this.getNode(value)
                return node ?? value
            }
        }
        else {
            return value
        }
    }

    #log(type, data) {
        this.#instructions.at(-1).push([type, data])
    }

    #serializeNodeProp(prop, value) {
        if (isNodeRef(prop)) {
            return value && value.id
        }
        else {
            return value
        }
    }

    #resetChanges() {
        this.#changes = new Map
    }

    #resetInstructions() {
        this.#instructions = [[]]
    }
}
