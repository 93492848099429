export const canUpdateNodeStyle = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const n = this.nodeProxy(node)

        if (! n.canWriteStyle()) {
            return false
        }

        return defaultActions.canUpdateNodeStyle(node)
    }
}
