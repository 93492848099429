import {Button} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonCode = (props) => {
    const [values] = useProperties()
    const {pkid, fcName, fcUserCode} = values

    if (! pkid) {
        return null
    }

    const handleClick = e => {
        openWindow(
            '/apiIDE',

            {
                fmFcId: pkid,
                fmFcName: fcName,
                fmFcUserCode: fcUserCode,
            },

            e.altKey ? '_self' : '_blank'
        )
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >查看代码</Button>
    )
}

export default PropertiesPaneButtonCode
