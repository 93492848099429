import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaLsi.mjs'
import Model from './ModelLsi.mjs'
import PropertiesPane from './PropertiesPaneLsi.jsx'
import FormChoose from './FormChooseLsi.jsx'
import TableChoose from './TableChooseLsi.jsx'
import api from './apiLsi.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(LSI_(ALG|INPUT|OUTPUT|SF))$/.test(type)
        },

        async choose(map, node) {
            const getQuery = query => {
                for (const n of node.chain) {
                    const {bizNodeType, lsCode} = n.data

                    if ('LS' === bizNodeType) {
                        return {lsCode, ...query}
                    }
                }

                return query
            }

            return this._choose(map, node, {getQuery})
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.PURPLE}
                    letters="LI"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                inputAlgList: [],
                outputAlgList: [],
                progList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.PURPLE}),
                shape: 'EllipseRectangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['progList'])
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    lsCode,
                    lsName,
                    pkid: lsId,
                } = n.data

                if ('LS' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        lsCode,
                        lsId,
                        lsName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        async _onInsert(map, node) {
            await (async () => {
                const p = node.trueParent

                if (! p) {
                    return
                }

                const {bizNodeType} = p.data

                if ('AR' === bizNodeType) {
                    const {pkid} = node.data
                    const treeData = await this.readTree({pkid})
                    const lsi = map.importTree(treeData)

                    const lsiInput = (() => {
                        for (const n of lsi.children) {
                            if ('LSI_INPUT' === n.data.bizNodeType) {
                                return n
                            }
                        }
                    })()

                    if (! lsiInput) {
                        map.deleteTree(lsi)
                        return
                    }

                    const addAr = node.nextSibling ?
                        ar => map.insertSiblingBefore(node.nextSibling, ar) :
                        ar => map.appendChild(node.parent, ar)

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            map.BizNode[t].classes.includes('category')
                        )

                        return {yieldChildren, yieldNode}
                    }

                    for (const n of map.dfs(lsiInput.children, next)) {
                        const {
                            arTypeCode,
                            [map.BizNode.AR.textProp]: t,
                        } = n.data

                        if ('VAR' !== arTypeCode) {
                            continue
                        }

                        const text = t.replace(
                            /^(?:<IP>)? *(\$*.*)$/,
                            '<IP> $1'
                        )

                        const data = {
                            ...n.data,
                            arTypeCode: 'SET_IP',
                            [map.BizNode.AR.textProp]: text,
                        }

                        const ar = map.importTree({data})
                        addAr(ar)
                    }

                    map.deleteTree(lsi)
                }
            })()

            await ProductNode._onInsert.call(this, map, node)
        },
    })
}
