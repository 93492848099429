import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Fm from '../components/PropertiesControllerFm.jsx'
import Ss from '../components/PropertiesControllerSs.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
//import ButtonDemo from './PropertiesPaneButtonDemo.jsx'
import ButtonPreview from './PropertiesPaneButtonPreview.jsx'
import Model from './ModelUi.mjs'

const PropertiesPaneUi = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                {/* <ButtonDemo /> */}
                <ButtonPreview />
            </>
        }

        controllers={
            <>
                <Ss isUi={1} />
                <Fm />
                <Text prop="uiUserCode" />
                <Text prop="uiName" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'CallUiDepend',
                    'CallUiEffect',
                    'Depend',
                    'Effect',
                    'Reference',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneUi
