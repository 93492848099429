import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelUe.mjs'
import UeType from './UeType.mjs'
import ButtonMakeSf from './PropertiesPaneButtonMakeSf/PropertiesPaneButtonMakeSf.jsx'

const PropertiesPaneUe = (props) => (
    <ComponentPropertiesPane
        buttons={
            <>
                <ButtonMakeSf />
            </>
        }

        controllers={
            <>
                <Text
                    label="界面名称"
                    prop="uiName"
                    readOnly
                />

                <Text prop="ueName" />

                <Select
                    allowClear={false}
                    options={UeType}
                    prop="ueTypeCode"
                />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Depend',
                    'Effect',
                    'Reference'
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneUe
