import useSWR from 'swr/immutable'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import ButtonImportProducts from '../components/PropertiesPaneButtonImportProducts.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import CategoryRootPropertiesPane from '../_CATEGORY_ROOT/CategoryRootPropertiesPane.jsx'

const useDm = (dmId) => {
    const fetchDm = (dmId) => runAsync(
        () => http.get(`/archdms/${dmId}`),

        {
            error: {
                content: (err) => `读取库表域失败: ${err.message}`,
            },

            loading: {
                content: '正在读取库表域，请稍候',
            },
        }
    )

    const {data} = useSWR(dmId, fetchDm)
    return data
}

export default function PropertiesPaneCatRootDm({nodes, ...props}) {
    const dm = useDm(nodes[0].data.dmId)

    if (! dm) {
        return null
    }

    return (
        <CategoryRootPropertiesPane
            buttons={<ButtonImportProducts />}

            controllers={
                <>
                    <Text
                        label="库表域代码"
                        prop="dmUserCode"
                    />

                    <Text
                        label="库表域名称"
                        prop="dmName"
                    />
                </>
            }

            properties={dm}
            {...props}
        />
    )
}
