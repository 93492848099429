import useSWR from 'swr/immutable'
import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import ButtonImportProducts from '../components/PropertiesPaneButtonImportProducts.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import CategoryRootPropertiesPane from '../_CATEGORY_ROOT/CategoryRootPropertiesPane.jsx'

const useVm = (vmId) => {
    const fetchVm = (vmId) => runAsync(
        () => http.get(`/archvms/${vmId}`),

        {
            error: {
                content: (err) => `读取程序模块失败: ${err.message}`,
            },

            loading: {
                content: '正在读取程序模块，请稍候',
            },
        }
    )

    const {data} = useSWR(vmId, fetchVm)
    return data
}

export default function PropertiesPaneCatRootVm({nodes, ...props}) {
    const vm = useVm(nodes[0].data.vmId)

    if (! vm) {
        return null
    }

    return (
        <CategoryRootPropertiesPane
            buttons={<ButtonImportProducts />}

            controllers={
                <>
                    <Text
                        label="程序模块代码"
                        prop="vmUserCode"
                    />

                    <Text
                        label="程序模块名称"
                        prop="vmName"
                    />
                </>
            }

            properties={vm}
            {...props}
        />
    )
}
