import BaseMap from '../../BaseMap.jsx'
import useMapData from './useMapData.mjs'

export default function SnapshotMap({id, patternRev, prjPatternId, ...props}) {
    const initData = useMapData(id)

    if (initData) {
        Object.assign(initData.data, {patternRev, prjPatternId})
    }

    return (
        <BaseMap
            initData={initData}
            {...props}
        />
    )
 }
