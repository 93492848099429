import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaUe.mjs'
import Model from './ModelUe.mjs'
import PropertiesPane from './PropertiesPaneUe.jsx'
import FormChoose from './FormChooseUe.jsx'
import TableChoose from './TableChooseUe.jsx'
import api from './apiUe.mjs'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(ALG_RULE|UE_(ALG|PROG))$/.test(type)
        },

        async choose(map, node) {
            const getQuery = query => {
                const {bizNodeType, ssCode, uiUserCode} = map.root.data

                if ('UI' === bizNodeType) {
                    return {ssCode, uiUserCode, ...query}
                }
                else {
                    return query
                }
            }

            return this._choose(map, node, {getQuery})
        },

        getIcons(map, node) {
            const {ueTypeCode} = node.data

            if ('INI' === ueTypeCode) {
                return [
                    <IconLetters
                        key="type"
                        letters="i"
                        textColor="#fff"
                    />
                ]
            }
            else if ('PUA' === ueTypeCode) {
                return [
                    <IconLetters
                        key="type"
                        letters="p"
                        textColor="#fff"
                    />
                ]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_BLUE,
                }),

                shape: 'HorizontalHexagon',
            }
        },

        async _atAttach(map, node, event) {
            await ComponentNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _onInsert(map, node) {
            await (async () => {
                const p = node.trueParent

                if (! p) {
                    return
                }

                const {bizNodeType} = p.data

                if (/^[FC]RES$/.test(bizNodeType)) {
                    const treeData = await this.readTree(map, node)

                    if (! treeData) {
                        return
                    }

                    const ue = map.importTree(treeData)

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            map.BizNode[t].classes.includes('category')
                        )

                        return {yieldChildren, yieldNode}
                    }

                    WALK_TREE:
                    for (const n of map.dfs(ue.children, next)) {
                        if ('UI' !== n.data.arTypeCode) {
                            continue
                        }

                        for (const nn of map.children(n)) {
                            if ('UI' !== nn.data.bizNodeType) {
                                continue
                            }

                            const {data} = nn

                            if (node.nextSibling?.data.pkid !== data.pkid) {
                                const ui = map.importTree({data})
                                map.insertSiblingAfter(node, ui)
                            }

                            break WALK_TREE
                        }
                    }

                    map.deleteTree(ue)
                }
            })()

            await ComponentNode._onInsert.call(this, map, node)
        },

        async _atCreate(map, node) {
            if (void 0 === node.data.ueTypeCode) {
                node.data = {...node.data, ueTypeCode: 'OTH'}
            }

            const childTypes = [
                'ALG_RULE',
                'UE_ALG',
                'UE_PROG',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await ComponentNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            if ('UI' !== type) {
                return
            }

            const pushData = this._getPushData(map, node, {child: []})
            const tree = this.exportTree(map, node, {compact: true})
            const ueMap = JSON.stringify(tree.children)
            data.umUeList.push({...pushData, ueMap})
        },
    })
}
