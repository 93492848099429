import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelKm.mjs'

const PropertiesPaneKm = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="kmTitle" />
                <TagSelect prop="kmKey" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneKm
