import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDbins.mjs'
import Model from './ModelDbins.mjs'
import PropertiesPane from './PropertiesPaneDbins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(DBINS_(DEPENDENCE_BY|DEPLOYED_IN))$/.test(type)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="D"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {dbInsEnv} = node.data

            if (dbInsEnv) {
                return `${defaultPrefix}【${dbInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        mapUpdateNodeData(map, node, data) {
            const d = {...data}
            const oldData = node.data

            const {
                dbInsIntranetIp,
                dbInsPort,
                dbInsSchema,
            } = {...oldData, ...data}

            if (
                dbInsIntranetIp &&
                dbInsPort &&
                dbInsSchema &&
                ! oldData.url &&
                ! Object.hasOwnProperty.call(data, 'url')
            ) {
                for (const n of map.trueChain(node.parent)) {
                    const {bizNodeType, dbmsCode} = n.data

                    if ('DB' === bizNodeType) {
                        const url = {
                            ORACLE: `jdbc:oracle:thin:@//${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}`,
                            MYSQL: `jdbc:mysql://${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}?allowMultiQueries=true`,
                            SQLSERVER: `jdbc:sqlserver://${dbInsIntranetIp}:${dbInsPort};DatabaseName=${dbInsSchema}`,
                            POSTGRESQL: `jdbc:postgresql://${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}`,
                        }[dbmsCode]

                        Object.assign(d, {url})
                        break
                    }
                }
            }

            return d
        },

        async _atCreate(map, node) {
            if (void 0 === node.data.dbDefault) {
                node.data = {...node.data, dbDefault: '0'}
            }

            const childTypes = [
                'DBINS_DEPLOYED_IN',
                'DBINS_DEPENDENCE_BY',
            ]

            for (const t of childTypes) {
                const n = map.createNode(t)
                map.appendChild(node, n)
            }

            await ComponentNode._atCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                DB: 'archDbInss',
                SSINS: 'archSsInsDependences',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {archDbInsDeploys: []}
            )

            const tree = this.exportTree(map, node, {compact: true})

            data[key].push({
                ...pushData,
                map: JSON.stringify(tree.children),

                ...({
                    SSINS: () => {
                        const {dbInsCode, prjId, rev, sVer} = node.data

                        return {
                            dependenceCode: dbInsCode,
                            dependencePrjId: prjId,
                            dependenceSVer: sVer,
                            dependenceType: 'DB',
                            dependenceVer: rev,
                        }
                    },
                }[type])?.(),
            })
        },
    })
}
