import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const [node] = selectedNodes
    const _n = map.nodeProxy(node)

    if (! _n.gotoCurrent || _n.canWriteData()) {
        return null
    }

    const onClick = e => {
        const target = e.altKey ? '_self' : '_blank'
        _n.gotoCurrent(target)
    }

    return {
        key: 'edit-design',
        label: '编辑模件',
        suffix: '',
        onClick,
    }
}
