export const canEditNodeText = () => defaultBehaviours => {
    return function (node) {
        if (! this.permission.has('write')) {
            return false
        }

        const n = this.nodeProxy(node)

        if (! n.canWriteData()) {
            return false
        }

        return defaultBehaviours.canEditNodeText(node)
    }
}

export const editNodeText = () => () => {
    return function (node, text) {
        const n = this.nodeProxy(node)
        const data = n.mapSetTextData(text)
        this.commands.updateNodeData([node], () => data)
    }
}

export const startEditNodeText = () => defaultBehaviours => {
    return function (node, text) {
        if (! this.permission.has('write')) {
            return
        }

        const n = this.nodeProxy(node)

        if (! n.canWriteData()) {
            return
        }

        return defaultBehaviours.startEditNodeText(node, text)
    }
}
