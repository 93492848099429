import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelBdrule.mjs'
import RuleType from './RuleType.jsx'

const PropertiesPaneBdrule = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="ruleTitle" />
                <Text prop="ruleUserCode" />

                <Select
                    options={RuleType}
                    prop="ruleType"
                />

                <TagSelect prop="ruleKey" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Depend',
                    'Effect',
                    'Reference'
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneBdrule
