import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectFm from '../FM/SelectFm.jsx'
import SfType from './SfType.jsx'

const FormItemFm = (props) => {
    return (
        <Form.Item
            label="功能模块"
            name="fms"
            {...props}
        >
            <SelectFm multiple />
        </Form.Item>
    )
}

const FormItemSfName = (props) => {
    return (
        <Form.Item
            label="系统功能名称"
            name="sfName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemSfNo = (props) => {
    return (
        <Form.Item
            label="系统功能代码"
            name="sfNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemSfType = (props) => {
    const options = SfType.map(([value, label]) => ({label, value}))

    return (
        <Form.Item
            label="系统功能类型"
            name="sfTypeCode"
            {...props}
        >
            <Select
                allowClear
                options={options}
            />
        </Form.Item>
    )
}

const FormChooseSf = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemFm />
            </Col>

            <Col span={6}>
                <FormItemSfNo />
            </Col>

            <Col span={6}>
                <FormItemSfName />
            </Col>

            <Col span={6}>
                <FormItemSfType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseSf
