import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import useSWR from './useSWRNoCacheOnMount.mjs'

const useProject = prjId => {
    const fetchProject = () => runAsync(
        async () => {
            const {
                desgAcc,
                prjName,
                prjNo,
                prjTypeCode,
                rev,
                typeName,
            } = await http.get(`/prjs/${prjId}/info`)

            return {
                desgAcc,
                prjName,
                prjNo,
                prjTypeCode,
                rev,
                typeName,
            }
        },

        {
            error: {
                content: (err) => `读取项目失败: ${err.message}`,
            },

            loading: {
                content: '正在读取项目，请稍候',
            },
        }
    )

    return useSWR(
        prjId ? `/projects/${prjId}` : undefined,
        fetchProject
    )
}

export default useProject
