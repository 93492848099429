import {escapeRegex} from 'hopedove-dom/str'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default (textSearch) => {
    const map = useMapContext()
    const matches = []

    if (textSearch) {
        const next = (chain) => {
            const isHidden = map.isNodeHidden(chain[0])

            return {
                yieldChildren: ! isHidden,
                yieldNode: ! isHidden,
            }
        }

        for (const node of map.dfs(map.root, next)) {
            const text = map.getNodeText(node)
            const prefix = map.getNodeTextPrefix(node)
            const suffix = map.getNodeTextSuffix(node)
            const s = [prefix, text, suffix].join('') || '(空白)'

            if (s) {
                const re = new RegExp(escapeRegex(textSearch), 'ig')
                const indexes = []
                let match

                while (null !== match) {
                    match = re.exec(s)

                    if (match) {
                        const {index} = match
                        indexes.push([index, index + textSearch.length])
                    }
                }

                if (0 < indexes.length) {
                    matches.push([node.id, s, indexes])
                }
            }
        }
    }

    return matches
}
