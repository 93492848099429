import insertNode from './insertNode.mjs'

export const canInsertSibling = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const _n = this.nodeProxy(node)

        if (! _n.canDuplicate()) {
            return false
        }

        return defaultActions.canInsertSibling(node)
    }
}

const getDefaultType = (map, node) => {
    const _n = map.nodeProxy(node.parent)
    return _n.defaultChildType()
}

export const insertSiblingAfter = () => () => {
    return function (nodes) {
        return insertNode(
            this,
            nodes,
            this.insertSiblingAfter.bind(this),
            getDefaultType
        )
    }
}

export const insertSiblingBefore = () => () => {
    return function (nodes) {
        return insertNode(
            this,
            nodes,
            this.insertSiblingBefore.bind(this),
            getDefaultType
        )
    }
}
