import {Button} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

const PropertiesPaneButtonPreview = (props) => {
    const [{demoType, pkid: uvId}] = useProperties()

    if (! (uvId && 'DEMO' === demoType)) {
        return null
    }

    const handleClick = e => {
        const target = e.altKey ? '_self' : '_blank'
        openWindow('/formilyPreview', {uvId}, target)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            预览
        </Button>
    )
}

export default PropertiesPaneButtonPreview
