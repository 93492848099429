export const canDuplicateTree = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const _n = this.nodeProxy(node)

        if (! _n.canDuplicate()) {
            return false
        }

        return defaultActions.canDuplicateTree(node)
    }
}

export const duplicateTree = () => () => {
    return async function (nodes) {
        const nodesToSelect = new Set()

        const duplicate = async (trees) => {
            const newNodes = []

            for (const [node, descendants] of trees) {
                duplicate(descendants)
                const _n = this.nodeProxy(node)
                const tree = _n.exportTree()
                const cloned = this.importTree(tree)
                this.insertSiblingAfter(node, cloned)
                newNodes.push(cloned)
            }

            await Promise.all(
                newNodes.map(async n => {
                    const _n = this.nodeProxy(n)
                    await _n.emitEventUp({type: 'attach'})

                    if (! n.isDeleted) {
                        this.logger.info('已复制分支', [n])
                        nodesToSelect.add(n)
                    }
                })
            )
        }

        const trees = this.treelize(nodes)
        await duplicate(trees)
        this.selectNodes(nodesToSelect)
    }
}
