import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/RightPanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelUialg.mjs'

const PropertiesPaneUiAlg = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />
                <Text prop="algUserCode" />
                <TagSelect prop="algKey" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Depend',
                    'Effect',
                    'Reference'
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneUiAlg
