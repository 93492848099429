export const canRefreshProduct = () => () => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const {bizNodeType, pkid} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能重载', [node])
            return false
        }

        const bn = this.BizNode[bizNodeType]

        if (! bn.readMap) {
            this.logger.error(`${bn.name}不支持重载`, [node])
            return false
        }

        return bn.canWriteData(this, node)
    }
}

export const refreshProduct = () => {
    return () => function (nodes) {
        return Promise.allSettled(
            [...nodes].map(
                async node => {
                    try {
                        const {bizNodeType, pkid} = node.data
                        const {data, root} = await this.BizNode[bizNodeType].readMap({pkid})
                        this.data = data
                        const _n = this.nodeProxy(this.root)
                        await _n.replace(root)
                        this.logger.info('重载模型制品成功', [node])
                        this.selectNodes([node])
                    }
                    catch (err) {
                        this.logger.error(
                            `重载模型制品失败: ${err.message}`,
                            [node]
                        )

                        err.nodes = [node]
                        throw err
                    }
                }
            )
        )
    }
}
