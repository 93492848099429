import extendNode from '../extendNode.mjs'
import {Modal} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaArchCatNode.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        async onDoubleClick(map, node, event) {
            const {childType, detailUrl} = this

            for (const n of map.dfs(node)) {
                const {bizNodeType} = n.data

                if (childType === bizNodeType) {
                    const {prjId, prjNo, sVer} = map.data

                    openWindow(
                        detailUrl,
                        {prjId, prjNo, sVer},
                        event.altKey ? '_self' : '_blank'
                    )

                    return
                }
            }

            const {name} = map.BizNode[childType]

            Modal.warn({
                content: `请先插入${name}制品，并保存地图后，才能进入${this.name}`,

                onOk: async () => {
                    if (! (
                        map.permission.has('write') &&
                        this.canWriteTree(map, node)
                    )) {
                        return
                    }

                    const p = (() => {
                        for (const n of map.bfs(node)) {
                            const _n = map.nodeProxy(n)

                            if (
                                _n.canLinkType(childType) ||
                                _n.canMountType(childType)
                            ) {
                                return n
                            }
                        }
                    })()

                    if (p) {
                        map.BizNode[childType].onInsertProduct(map, [p])
                    }
                },
            })
        },
    })
}
