import {useState} from 'react'
import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import {ModelContext} from '../../bizNodeModel.mjs'
import {MapContext} from '@/components/MindMap/index.mjs'
import {PropertiesContext} from '../../SidePanel/RightPanel/Properties.mjs'

const ModalProperties = ({map, node, onOk, ...props}) => {
    const [values, setValues] = useState(node.data)

    const update = (updates) => {
        setValues((values) => ({...values, ...updates}))
    }

    const handleOk = (close) => {
        map.commands.updateNodeData([node], () => values)
        onOk(close)
    }

    return (
        <MapContext.Provider value={map}>
            <PropertiesContext.Provider value={[values, update]}>
                <Modal
                    title="属性"
                    width="90%"
                    onOk={handleOk}
                    {...props}
                />
            </PropertiesContext.Provider>
        </MapContext.Provider>
    )
}

export default (ModalContent) => {
    const openModal = useOpenModal()

    return (map, node, Model) => {
        const {bizNodeType} = node.data
        const canWriteData = map.BizNode[bizNodeType].canWriteData(map, node)

        const isReadOnly = ! (
            canWriteData &&
            map.permission.has('write')
        )

        return openModal(
            <ModalProperties
                map={map}
                node={node}
            >
                <ModelContext.Provider value={Model}>
                    <ReadOnlyContext.Provider value={isReadOnly}>
                        <ModalContent />
                    </ReadOnlyContext.Provider>
                </ModelContext.Provider>
            </ModalProperties>
        )
    }
}
