import http from '@/biz/http.mjs'
import metaBizNode from '../metaBizNode.mjs'

export default {
    create: (args) => http.post('/basmapvers', args),

    fetchIdByRev: async ({bizNodeType, pkid, rev}) => {
        const {pkid: id} = await http.get(
            '/basmapvers/judgeVer',
            {bizNodeType, pkid, rev}
        )

        return id
    },

    list: ({dataId}) => http.get('/basmapvers', {dataId}),

    read: async ({pkid}) => {
        const {
            crtName: rlsName,
            crtTime: rlsTime,
            dataName,
            dataMap,
            dataVer: mapRev,
        } = await http.get(`/basmapvers/${pkid}`)

        const map = JSON.parse(dataMap)

        const mapTypeName = (() => {
            const {mapTypeCode, mapTypeName} = map.data

            const name = (() => {
                if (mapTypeCode.startsWith('_')) {
                    const t = mapTypeCode.replace(/^_([^_]+)_MAP$/, '$1')
                    return metaBizNode[t]?.name
                }
                else {
                    return {
                        SA_MAP: '模型总图',
                        BIZ_MAP: '业务地图',
                        DATA_MAP: '库表地图',
                        DEP_MAP: '部署地图',
                        FC_MAP: '程序地图',
                        INF_MAP: '接口地图',
                        SF_MAP: '功能地图',
                        UI_MAP: '界面地图',
                    }[mapTypeCode]
                }
            })()

            return name ?? mapTypeName
        })()

        const mapName = (() => {
            const {mapTypeCode} = map.data

            const name = {
                SA_MAP: '模型总图',
                _BA_MAP: '业务结构',
                _FA_MAP: '功能结构',
                _UA_MAP: '界面结构',
            }[mapTypeCode]

            return name ?? dataName
        })()

        Object.assign(map.data, {
            crtName: '',
            mapName,
            mapRev,
            mapTypeName,
            rlsName,
            rlsTime,
            snapshotId: pkid,
            stsCode: '',
            uptName: '',
        })

        return map
    },

    readByRev: async args => {
        const {
            crtName: rlsName,
            crtTime: rlsTime,
            dataName: mapName,
            dataMap,
            dataVer: mapRev,
            ...data
        } = await http.get('/basmapvers/ver', args)

        const map = JSON.parse(dataMap)

        Object.assign(map.data, {
            ...data,
            crtName: '',
            mapName,
            mapRev,
            rlsName,
            rlsTime,
            stsCode: '',
            uptName: '',
        })

        return map
    },
}
