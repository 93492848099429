export const canDeleteTree = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const _n = this.nodeProxy(node)

        if (! _n.canDeleteTree()) {
            return false
        }

        return defaultActions.canDeleteTree(node)
    }
}

export const deleteTree = () => defaultActions => {
    return async function (nodes) {
        const confirmedNodes = []

        for (const n of this.topNodes(nodes)) {
            const _n = this.nodeProxy(n)
            const isCancelled = await _n.beforeDeleteTree()

            if (! isCancelled) {
                confirmedNodes.push(n)
            }
        }

        await defaultActions.deleteTree(confirmedNodes)

        await Promise.all(
            confirmedNodes.map(async n => {
                const _n = this.nodeProxy(n)
                await _n.emitEventUp({type: 'delete'})
            })
        )
    }
}
