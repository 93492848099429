import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaMid.mjs'
import Model from './ModelMid.mjs'
import PropertiesPane from './PropertiesPaneMid.jsx'
import FormChoose from './FormChooseMid.jsx'
import TableChoose from './TableChooseMid.jsx'
import api from './apiMid.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        canLinkType(map, node, type) {
            return /^(MIDINS)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(MID_(MIDINS))$/.test(type)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="M"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.GREEN_YELLOW,
                }),
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archMiddlewareInss: []})
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archMiddlewareInss'])
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            const p = node.trueParent

            if (p) {
                const {bizNodeType} = p.data

                if (/^(SS_MID|DEP_MID)$/.test(bizNodeType)) {
                    for (const n of [...node.children]) {
                        if ('MID_MIDINS' === n.data.bizNodeType) {
                            map.deleteNode(n)
                        }
                        else {
                            map.deleteTree(n)
                        }
                    }
                }
            }

            return newDepth
        },
    })
}
