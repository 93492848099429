const pasteTree = async (map, nodes, insert) => {
    const data = await map.actions.readTreeFromClipboard()
    const nodesToSelect = new Set()

    await Promise.all(
        nodes.map(async node => Promise.all(
            data.trees.map(async tree => {
                const n = map.importTree(tree)
                insert(node, n)
                const _n = map.nodeProxy(n)
                await _n.emitEventUp({type: 'attach'})

                if (! n.isDeleted) {
                    map.logger.info('从剪贴板粘贴', [n])
                    nodesToSelect.add(n)
                }
            })
        ))
    )

    map.selectNodes(nodesToSelect)
}

export const canPasteTreeAfter = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        return defaultActions.canPasteTreeAfter(node)
    }
}

export const canPasteTreeAppend = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        return defaultActions.canPasteTreeAppend(node)
    }
}

export const pasteTreeAfter = () => () => {
    return async function (nodes) {
        await pasteTree(
            this,
            nodes,
            this.insertSiblingAfter.bind(this)
        )
    }
}

export const pasteTreeAppend = () => () => {
    return async function (nodes) {
        await pasteTree(
            this,
            nodes,
            this.appendChild.bind(this)
        )
    }
}
