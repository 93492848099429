export const canSaveProduct = () => () => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const {bizNodeType, pkid, stsCode} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能保存', [node])
            return false
        }

        if (! /^(DRAFT|REVISE)$/.test(stsCode)) {
            this.logger.error('只有草稿/修订状态才能保存', [node])
            return false
        }

        const bn = this.BizNode[bizNodeType]

        if (! bn.update) {
            this.logger.error(`${bn.name}不支持保存`, [node])
            return false
        }

        return bn.canWriteData(this, node)
    }
}

export const saveProduct = () => () => {
    return function (nodes) {
        return Promise.allSettled([...nodes].map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data

                    const updates = await this.BizNode[bizNodeType].update(
                        this, node
                    )

                    node.data = {...node.data, ...updates}
                    this.logger.info('保存模型制品成功', [node])
                }
                catch (err) {
                    this.logger.error(
                        `保存模型制品失败: ${err.message}`,
                        [node]
                    )

                    err.nodes = [node]
                    throw err
                }
            }
        ))
    }
}
