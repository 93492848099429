import {useEffect, useRef, useState} from 'react'
import {subscribe, unsubscribe} from '@/script/event.mjs'
import History from '@/script/History.mjs'

export default () => {
    const refHistory = useRef(new History)
    const refIsChanging = useRef(false)
    const history = refHistory.current
    const refLastSelectedNodeIds = useRef([])

    const extensions = () => ({
        get canRedoChange() {
            return history.cursor < history.end
        },

        get canUndoChange() {
            return - 1 < history.cursor
        },

        async redoChange() {
            if (! this.canRedoChange) {
                return
            }

            history.forward()
            const [instructions, selectedNodeIds] = history.peek()
            refIsChanging.current = true

            await this.execute(() => {
                this.do(instructions)

                const selectedNodes = selectedNodeIds.map(
                    id => this.getNode(id)
                )

                this.selectNodes(selectedNodes)
            })

            refIsChanging.current = false
        },

        async undoChange() {
            if (! this.canUndoChange) {
                return
            }

            const [instructions, , selectedNodeIds] = history.back()
            refIsChanging.current = true

            await this.execute(() => {
                this.revert(instructions)

                const selectedNodes = selectedNodeIds.map(
                    id => this.getNode(id)
                )

                this.selectNodes(selectedNodes)
            })

            refIsChanging.current = false
        },

        useChangeHistory() {
            const getHistoryStates = () => {
                const canUndo = this.canUndoChange
                const canRedo = this.canRedoChange
                return {canRedo, canUndo}
            }

            const [states, setStates] = useState(getHistoryStates)

            useEffect(
                () => {
                    const handleHistoryChange = () => {
                        setStates(getHistoryStates())
                    }

                    subscribe(history, 'change', handleHistoryChange)

                    return () => {
                        unsubscribe(history, 'change', handleHistoryChange)
                    }
                },

                []
            )

            return states
        }
    })

    const watchers = {
        commit(instructions) {
            if (refIsChanging.current) {
                return
            }

            history.push([
                instructions,
                [...this.selectedNodes].map(n => n.id),
                refLastSelectedNodeIds.current,
            ])
        },

        task_start() {
            refLastSelectedNodeIds.current = [...this.selectedNodes].map(
                n => n.id
            )
        },
    }

    return {extensions, watchers}
}
