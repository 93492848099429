import parseMap from '../../scripts/parseMap.mjs'
import BaseMap from '../../BaseMap.jsx'

const components = {
    LeftPanel: () => null,
}

export default function EmbeddedMap({
    mapData,
    patternRev,
    prjPatternId,
    ...props
}) {
    const initData = parseMap(mapData)

    if (initData) {
        Object.assign(initData.data, {patternRev, prjPatternId})
    }

    return (
        <BaseMap
            components={components}
            initData={initData}
            {...props}
        />
    )
}
