import openWindow from '@/script/openWindow.mjs'
import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import Color from '../Color.mjs'
import meta from './metaCatData.mjs'
import PropertiesPane from './PropertiesPaneCatData.jsx'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,
        PropertiesPane,

        canMountType(map, node, type) {
            return /^(BM)$/.test(type)
        },

        //getRev(map, node) {
            //return map.BizNode.BA.getRev.call(this, map, node)
        //},

        getStyle(map, node) {
            return {
                ...CategoryNode.getStyle.call(this, map, node),
                backgroundColor: Color.CYAN,
            }
        },

        //getVersion(map, node) {
            //return map.BizNode.BA.getVersion.call(this, map, node)
        //},

        //isOutdated(map, node) {
            //return map.BizNode.BA.isOutdated.call(this, map, node)
        //},

        //async shrink(map, node) {
            //map.deleteChildren(node)
        //},

        async onDoubleClick(map, node, event) {
            const {prjId, prjNo, sVer} = map.data

            const [ba] = map.queryNodes({
                algo: 'bfs',
                data: {bizNodeType: 'BA'}
            })

            if (! ba) {
                map.logger.error('找不到业务模型节点，不能跳转', [node])
                return
            }

            const {rev} = ba.data

            if (! (prjId && prjNo && (rev || 0 === rev) && sVer)) {
                map.logger.error('数据不全，不能跳转', [node])
                return
            }

            openWindow(
                '/ProjectDataMap',
                {prjId, prjNo, rev, sVer},
                event.altKey ? '_self' : '_blank'
            )
        },

        //async _grow(map, node, depth) {
            //const tree = await this._readGrowTree(map, node)

            //if (! tree) {
                //return depth
            //}

            //await this.replace(map, node, tree)
            //return depth + 1
        //},

        //async _readGrowTree(map, node) {
            //const {prjId, sVer} = map.data
            //const {baMap} = await http.get(`/archbas/is/${prjId}/${sVer}`)
            //const {root: {children}} = parseMap(baMap)
            //return {children}

            //for (const n of map.bfs(map.root)) {
                //if ('BA' === n.data.bizNodeType) {
                    //const _n = map.nodeProxy(n)

                    //if (n.firstChild) {
                        //const tree = _n.exportTree()

                        //const refine = nodes => {
                            //return nodes
                                //.filter(n => 'BM' === n.data.bizNodeType)
                                //.map(n => {
                                    //const children = refine(n.children)
                                    //return {...n, children}
                                //})
                        //}

                        //const children = refine(tree.children)
                        //return {children}
                    //}
                    //else {
                        //const {children} = await _n._readGrowTree()
                        //return {children}
                    //}
                //}
            //}
        //},
    })
}
