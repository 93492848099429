export const canMoveTree = () => defaultActions => {
    return function (node) {
        if (! this.permission.has('write')) {
            this.logger.error('没有权限', [node])
            return false
        }

        const _n = this.nodeProxy(node)

        if (! _n.canMove()) {
            return false
        }

        return defaultActions.canMoveTree(node)
    }
}
