import {Button} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonEditMap({
    children = '编辑地图',
    ...props
}) {
    const map = useMapContext()

    if (! map.data.rlsTime) {
        return null
    }

    const handleClick = e => {
        const {pkid: id} = map.data
        const target = e.altKey ? '_self' : '_blank'
        openWindow('/SoftwareMap', {id}, target)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            {children}
        </Button>
    )
}
