import {css} from '@emotion/react'
import {useTreeDocContext} from './TreeDocContext.jsx'

const cssSelection = css({
    position: 'absolute',
    backgroundColor: 'rgba(45, 189, 255, .2)',
    border: '1px solid rgba(45, 189, 255, .5)',
    borderRadius: 4,
    pointerEvent: 'none',
})

export default function CanvasSelection(props) {
    const doc = useTreeDocContext()
    const selection = doc.useCanvasSelection()

    if (! selection) {
        return null
    }

    const [x0, y0, x1, y1] = selection

    const style = {
        top: Math.min(y0, y1),
        left: Math.min(x0, x1),
        width: Math.abs(x0 - x1),
        height: Math.abs(y0 - y1),
    }

    return (
        <div
            css={cssSelection}
            style={style}
            {...props}
        ></div>
    )
}
